<template>
 <div>
  
    <div class="breadCurmb mt-3">
      <label>
        <router-link to="/admin/home">{{ $t("Home") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $t("Resturant") }}</label>
      <span>/</span>
      <label>
        <router-link :to="{name: 'recipie'}">{{ $t("Recipies") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $route.params.id? $t("Edit"): $t('Add') }}</label>

    </div>

  <v-toolbar-title class="mt-3">{{$route.params.id? $t('EditRecipie')  : $t('AddNewRecipie') }}</v-toolbar-title>


   <v-tabs class="mt-2"
      v-model="activeName"
      background-color="primary"
      dark
    >
      <v-tab>{{ $t('PrimaryData') }}</v-tab>
      <v-tab>{{ $t('Components') }}</v-tab>
   </v-tabs>

    <v-tabs-items v-model="activeName">

       <v-tab-item>
           <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col class="py-0" :cols="6">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.name_ar`),
              }"
            >
            <label>{{$t('NameAr')}}</label>
              <v-text-field
                class="d-block my-2"
                type="text"
                name="name_ar"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('NameAr')"
                hide-details
                dense
                outlined
                v-model="formData.name.ar"
              ></v-text-field>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.name_ar`)"
            >
              {{ errors.first(`addEditValidation.name_ar`) }}
            </div>
          </v-col>

             <v-col class="py-0" :cols="6">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.name_en`),
              }"
            >
             <label>{{$t('NameEn')}}</label>
              <v-text-field
                class="d-block my-2"
                type="text"
                name="name_en"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('NameEn')"
                hide-details
                dense
                outlined
                v-model="formData.name.en"
              ></v-text-field>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.name_en`)"
            >
              {{ errors.first(`addEditValidation.name_en`) }}
            </div>
          </v-col>


          <v-col class="py-0" :cols="6">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.description_ar`),
              }"
            >
            <label>{{$t('DescriptionAr')}}</label>
              <v-textarea
                class="d-block my-2"
                type="text"
                name="description_ar"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('DescriptionAr')"
                hide-details
                dense
                outlined
                rows="3"
                v-model="formData.description.ar"
              ></v-textarea>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.description_ar`)"
            >
              {{ errors.first(`addEditValidation.description_ar`) }}
            </div>
          </v-col>

             <v-col class="py-0" :cols="6">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.description_en`),
              }"
            >
            <label>{{$t('DescriptionEn')}}</label>
              <v-textarea
                class="d-block my-2"
                type="text"
                name="description_en"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('DescriptionEn')"
                hide-details
                dense
                outlined
                rows="3"
                v-model="formData.description.en"
              ></v-textarea>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.description_en`)"
            >
              {{ errors.first(`addEditValidation.description_en`) }}
            </div>
          </v-col>


           

           <v-col class="py-0" :cols="4">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.tool_id`),
              }"
            >
            <label>{{$t('Tool')}}</label>
             <v-autocomplete
                class="d-block my-2"
                name="tool_id"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('Tool')"
                hide-details
                dense
                outlined
                multiple
                v-model="formData.tool_id"
                item-value="id"
                item-text="name"
                :items="listTool"
                clearable
              ></v-autocomplete>
              
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.tool_id`)"
            >
              {{ errors.first(`addEditValidation.tool_id`) }}
            </div>
          </v-col>

            <v-col class="py-0" :cols="4">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.cuisine_id`),
              }"
            >
             <label>{{$t('Cuisine')}}</label>
             <v-autocomplete
                class="d-block my-2"
                name="cuisine_id"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('Cuisine')"
                hide-details
                dense
                outlined
                multiple
                v-model="formData.cuisine_id"
                item-value="id"
                item-text="name"
                :items="listCuisine"
                clearable
              ></v-autocomplete>
              
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.cuisine_id`)"
            >
              {{ errors.first(`addEditValidation.cuisine_id`) }}
            </div>
          </v-col>



        </v-row>
      </v-container>
    </v-card-text>

       </v-tab-item>


        <v-tab-item>
         <v-container fluid>

          <v-row class="mt-3">

                <v-col class="py-0" :cols="4">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.type`),
              }"
            >
            <label>{{$t('Type')}}</label>
             <v-autocomplete
                class="d-block my-2"
                name="type"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('Type')"
                hide-details
                dense
                outlined
                v-model="formData.type"
                item-value="id"
                item-text="name"
                :items="listType"
                clearable
              ></v-autocomplete>
              
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.type`)"
            >
              {{ errors.first(`addEditValidation.type`) }}
            </div>
          </v-col>

          </v-row>
          <v-row class="mt-3">


             <v-col class="py-0" :cols="4" v-if="formData.type == 2">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.group`),
              }"
            >
            <label>{{$t('Groups')}}</label>
             <v-autocomplete
                class="d-block my-2"
                name="group"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('Groups')"
                hide-details
                dense
                outlined
                v-model="formData.group_id"
                item-value="id"
                item-text="name"
                :items="listGroup"
                clearable
              ></v-autocomplete>
              
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.group`)"
            >
              {{ errors.first(`addEditValidation.group`) }}
            </div>
          </v-col>

            
             <v-col class="py-0" :cols="4" v-if="formData.type == 3">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.ingredient_primary_id`),
              }"
            >
            <label>{{$t('IngredientPrimary')}}</label>
             <v-autocomplete
                class="d-block my-2"
                name="ingredient_primary_id"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('IngredientPrimary')"
                hide-details
                dense
                outlined
                v-model="formData.ingredient_primary_id"
                item-value="id"
                item-text="name"
                :items="listPrimaryIngredient"
                clearable
              ></v-autocomplete>
              
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.ingredient_primary_id`)"
            >
              {{ errors.first(`addEditValidation.ingredient_primary_id`) }}
            </div>
          </v-col>


            <v-col class="py-0" :cols="4" v-if="formData.type == 3">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.protin_carb`),
              }"
            >
            <label>{{$t('ProtinCarb')}}</label>
             <v-autocomplete
                class="d-block my-2"
                name="protin_carb"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('ProtinCarb')"
                hide-details
                dense
                outlined
                v-model="protin_carb"
                item-value="id"
                item-text="name"
                :items="listProtinCarb"
                clearable
              ></v-autocomplete>
              
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.protin_carb`)"
            >
              {{ errors.first(`addEditValidation.protin_carb`) }}
            </div>
          </v-col>


          </v-row>
           <div class="mt-5">
            <el-button @click="addNew" type="primary float-right mb-5">{{$t('Add')}}</el-button>
           </div>

           <table id="pacakgeFeaturesTable">
            <tr>
              <th>#</th>
              <th>المكون غير الأساسي</th>
              <th>الكمية</th>
              <th>العمليات</th>
            </tr>

            <tr v-for="(l, lIndex) in formData.ingredient" :key="lIndex">
              <td>
              {{  lIndex + 1}}
              </td>
              <td>
                <div
              :class="{
                'has-error': errors.has(`addEditValidation.id${lIndex}`),
              }"
            >
            <label>{{$t('InPrimaryComponent')}}</label>
              <v-autocomplete
                class="d-block my-2"
                :name="`id${lIndex}`"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('InPrimaryComponent')"
                hide-details
                dense
                outlined
                v-model="l.id"
                item-value="id"
                item-text="name"
                :items="listIngredient"
                clearable
              ></v-autocomplete>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.id${lIndex}`)"
            >
              {{ errors.first(`addEditValidation.id${lIndex}`) }}
            </div>
              </td>

              <td>
                 <div
              :class="{
                'has-error': errors.has(`addEditValidation.quantity${lIndex}`),
              }"
            >
            <label>{{$t('Quantity')}}</label>
              <v-text-field
                class="d-block my-2"
                :name="`quantity${lIndex}`"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('Quantity')"
                hide-details
                dense
                outlined
                v-model="l.quantity"
              ></v-text-field>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.quantity${lIndex}`)"
            >
              {{ errors.first(`addEditValidation.quantity${lIndex}`) }}
            </div>
              </td>
              <td>

                  <v-icon
          class="my-0 mr-2 ml-2"
          dense
          @click="
            removeRow(lIndex);
          "
          color="danger"
          >mdi-delete</v-icon
        >

              </td>
            </tr>

           </table>
         </v-container>
       </v-tab-item>



    </v-tabs-items>

     <v-container fluid>
        <v-row class="float-left">
          <v-btn
            class="mr-2 ml-5"
            color="success"
            dark
            outlined
            small
            @click="save"
          >
            {{ $t("Save") }}
          </v-btn>
        </v-row>
      </v-container>
 </div>
</template>

<script>
export default {
  name: "recipieAddEdit",
  data() {
    return {
      activeName: 'primaryData',
      formData: {
        type:1,
        id: 0,
        name: {
          ar: null,
          en: null
        },
        description: {
          ar: null,
          en: null
        },
        protein: false,
        carb: false,
        cuisine_id: [],
        group_id:null,
        ingredient: [],  // id,quantity
        ingredient_primary_id: null,
        tool_id: [],
      },
        protin_carb: null,
        listType: [
          {
            id: 2,
            name: 'ميزة'
          },
          {
            id: 3,
            name: 'مكون أساسي'
          }
        ],
        listProtinCarb: [
          {
            id: 1,
            name: this.$t('Protin')
          },
          {
            id: 2,
            name: this.$t('Carb')
          }
        ],
      listTool: [],
      listGroup: [],
      listCuisine: [],
      listIngredient: [],
      listPrimaryIngredient: [],
    }
  },
   methods: {
    removeRow(index) {
      this.formData.ingredient.splice(index,1)
    },
    addNew() {
      let listObj= {
        id: null,
        quantity: null,
      };
      this.formData.ingredient.push(listObj);
    },
      save() {

        let sendData= JSON.parse(JSON.stringify(this.formData));

        if(sendData.type == 2) {
        sendData.ingredient_primary_id = null;
            sendData.protein = null;
            sendData.carb = null;
        }
        else if (sendData.type == 3) {
        if(this.protin_carb == 1) sendData.protein = true;
        if(this.protin_carb == 2) sendData.carb = true;
            sendData.group_id = null;
        }
        
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");
        if(this.$route.params.id) {
          //edit
          this.$store.dispatch(`recipie/updateData`, sendData).then((res)=> {
            this.notifySuccess("Update", res)
            this.$router.push({name:'recipie'})
          });
        }
        else {
          this.$store.dispatch(`recipie/saveData`, sendData).then((res)=> {
            this.notifySuccess("Save", res)
            this.$router.push({name:'recipie'})
          });
        }
      });
      }
    },
    created() {
      if(this.$route.params.id) {
        this.$store.dispatch(`recipie/findData`, this.$route.params.id).then((res)=> {
          this.formData= res.data;
        if(res.data.protein == 1){ 
          this.formData.carb = true;
          this.protin_carb= 1;
        }
        else if(res.data.carb == 1) {
          this.formData.protein = true;
          this.protin_carb= 2;
        }


          });
      }

      this.$store.dispatch(`recipie/listTool`).then(res => {
        this.listTool= res.data;
      })

      this.$store.dispatch(`recipie/listGroup`).then(res => {
        this.listGroup= res.data;
      })

      this.$store.dispatch(`recipie/listCuisine`).then(res => {
        this.listCuisine= res.data;
      })

      this.$store.dispatch(`recipie/listIngredient`).then(res => {
        this.listIngredient= res.data;
      })

      this.$store.dispatch(`recipie/listPrimaryIngredient`).then(res => {
        this.listPrimaryIngredient= res.data;
      })

    }
};
</script>

<style>

#pacakgeFeaturesTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#pacakgeFeaturesTable td, #pacakgeFeaturesTable th {
  border: 1px solid #ccc !important;
  padding: 8px;
}

#pacakgeFeaturesTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: right;
  background-color: #04AA6D;
  color: white;
}
</style>