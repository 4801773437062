var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"breadCurmb mt-3"},[_c('label',[_c('router-link',{attrs:{"to":"/admin/home"}},[_vm._v(_vm._s(_vm.$t("Home")))])],1),_c('span',[_vm._v("/")]),_c('label',{attrs:{"active":""}},[_vm._v(_vm._s(_vm.$t("Resturant")))]),_c('span',[_vm._v("/")]),_c('label',[_c('router-link',{attrs:{"to":{name: 'recipie'}}},[_vm._v(_vm._s(_vm.$t("Recipies")))])],1),_c('span',[_vm._v("/")]),_c('label',{attrs:{"active":""}},[_vm._v(_vm._s(_vm.$route.params.id? _vm.$t("Edit"): _vm.$t('Add')))])]),_c('v-toolbar-title',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$route.params.id? _vm.$t('EditRecipie') : _vm.$t('AddNewRecipie')))]),_c('v-tabs',{staticClass:"mt-2",attrs:{"background-color":"primary","dark":""},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('v-tab',[_vm._v(_vm._s(_vm.$t('PrimaryData')))]),_c('v-tab',[_vm._v(_vm._s(_vm.$t('Components')))])],1),_c('v-tabs-items',{model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('v-tab-item',[_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":6}},[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.name_ar`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('NameAr')))]),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"d-block my-2",attrs:{"type":"text","name":"name_ar","data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('NameAr'),"hide-details":"","dense":"","outlined":""},model:{value:(_vm.formData.name.ar),callback:function ($$v) {_vm.$set(_vm.formData.name, "ar", $$v)},expression:"formData.name.ar"}})],1),(_vm.errors.has(`addEditValidation.name_ar`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.name_ar`))+" ")]):_vm._e()]),_c('v-col',{staticClass:"py-0",attrs:{"cols":6}},[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.name_en`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('NameEn')))]),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:(''),expression:"''"}],staticClass:"d-block my-2",attrs:{"type":"text","name":"name_en","data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('NameEn'),"hide-details":"","dense":"","outlined":""},model:{value:(_vm.formData.name.en),callback:function ($$v) {_vm.$set(_vm.formData.name, "en", $$v)},expression:"formData.name.en"}})],1),(_vm.errors.has(`addEditValidation.name_en`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.name_en`))+" ")]):_vm._e()]),_c('v-col',{staticClass:"py-0",attrs:{"cols":6}},[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.description_ar`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('DescriptionAr')))]),_c('v-textarea',{directives:[{name:"validate",rawName:"v-validate",value:(''),expression:"''"}],staticClass:"d-block my-2",attrs:{"type":"text","name":"description_ar","data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('DescriptionAr'),"hide-details":"","dense":"","outlined":"","rows":"3"},model:{value:(_vm.formData.description.ar),callback:function ($$v) {_vm.$set(_vm.formData.description, "ar", $$v)},expression:"formData.description.ar"}})],1),(_vm.errors.has(`addEditValidation.description_ar`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.description_ar`))+" ")]):_vm._e()]),_c('v-col',{staticClass:"py-0",attrs:{"cols":6}},[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.description_en`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('DescriptionEn')))]),_c('v-textarea',{directives:[{name:"validate",rawName:"v-validate",value:(''),expression:"''"}],staticClass:"d-block my-2",attrs:{"type":"text","name":"description_en","data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('DescriptionEn'),"hide-details":"","dense":"","outlined":"","rows":"3"},model:{value:(_vm.formData.description.en),callback:function ($$v) {_vm.$set(_vm.formData.description, "en", $$v)},expression:"formData.description.en"}})],1),(_vm.errors.has(`addEditValidation.description_en`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.description_en`))+" ")]):_vm._e()]),_c('v-col',{staticClass:"py-0",attrs:{"cols":4}},[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.tool_id`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('Tool')))]),_c('v-autocomplete',{directives:[{name:"validate",rawName:"v-validate",value:(''),expression:"''"}],staticClass:"d-block my-2",attrs:{"name":"tool_id","data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('Tool'),"hide-details":"","dense":"","outlined":"","multiple":"","item-value":"id","item-text":"name","items":_vm.listTool,"clearable":""},model:{value:(_vm.formData.tool_id),callback:function ($$v) {_vm.$set(_vm.formData, "tool_id", $$v)},expression:"formData.tool_id"}})],1),(_vm.errors.has(`addEditValidation.tool_id`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.tool_id`))+" ")]):_vm._e()]),_c('v-col',{staticClass:"py-0",attrs:{"cols":4}},[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.cuisine_id`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('Cuisine')))]),_c('v-autocomplete',{directives:[{name:"validate",rawName:"v-validate",value:(''),expression:"''"}],staticClass:"d-block my-2",attrs:{"name":"cuisine_id","data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('Cuisine'),"hide-details":"","dense":"","outlined":"","multiple":"","item-value":"id","item-text":"name","items":_vm.listCuisine,"clearable":""},model:{value:(_vm.formData.cuisine_id),callback:function ($$v) {_vm.$set(_vm.formData, "cuisine_id", $$v)},expression:"formData.cuisine_id"}})],1),(_vm.errors.has(`addEditValidation.cuisine_id`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.cuisine_id`))+" ")]):_vm._e()])],1)],1)],1)],1),_c('v-tab-item',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-3"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":4}},[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.type`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('Type')))]),_c('v-autocomplete',{directives:[{name:"validate",rawName:"v-validate",value:(''),expression:"''"}],staticClass:"d-block my-2",attrs:{"name":"type","data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('Type'),"hide-details":"","dense":"","outlined":"","item-value":"id","item-text":"name","items":_vm.listType,"clearable":""},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}})],1),(_vm.errors.has(`addEditValidation.type`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.type`))+" ")]):_vm._e()])],1),_c('v-row',{staticClass:"mt-3"},[(_vm.formData.type == 2)?_c('v-col',{staticClass:"py-0",attrs:{"cols":4}},[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.group`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('Groups')))]),_c('v-autocomplete',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"d-block my-2",attrs:{"name":"group","data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('Groups'),"hide-details":"","dense":"","outlined":"","item-value":"id","item-text":"name","items":_vm.listGroup,"clearable":""},model:{value:(_vm.formData.group_id),callback:function ($$v) {_vm.$set(_vm.formData, "group_id", $$v)},expression:"formData.group_id"}})],1),(_vm.errors.has(`addEditValidation.group`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.group`))+" ")]):_vm._e()]):_vm._e(),(_vm.formData.type == 3)?_c('v-col',{staticClass:"py-0",attrs:{"cols":4}},[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.ingredient_primary_id`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('IngredientPrimary')))]),_c('v-autocomplete',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"d-block my-2",attrs:{"name":"ingredient_primary_id","data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('IngredientPrimary'),"hide-details":"","dense":"","outlined":"","item-value":"id","item-text":"name","items":_vm.listPrimaryIngredient,"clearable":""},model:{value:(_vm.formData.ingredient_primary_id),callback:function ($$v) {_vm.$set(_vm.formData, "ingredient_primary_id", $$v)},expression:"formData.ingredient_primary_id"}})],1),(_vm.errors.has(`addEditValidation.ingredient_primary_id`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.ingredient_primary_id`))+" ")]):_vm._e()]):_vm._e(),(_vm.formData.type == 3)?_c('v-col',{staticClass:"py-0",attrs:{"cols":4}},[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.protin_carb`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('ProtinCarb')))]),_c('v-autocomplete',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"d-block my-2",attrs:{"name":"protin_carb","data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('ProtinCarb'),"hide-details":"","dense":"","outlined":"","item-value":"id","item-text":"name","items":_vm.listProtinCarb,"clearable":""},model:{value:(_vm.protin_carb),callback:function ($$v) {_vm.protin_carb=$$v},expression:"protin_carb"}})],1),(_vm.errors.has(`addEditValidation.protin_carb`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.protin_carb`))+" ")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"mt-5"},[_c('el-button',{attrs:{"type":"primary float-right mb-5"},on:{"click":_vm.addNew}},[_vm._v(_vm._s(_vm.$t('Add')))])],1),_c('table',{attrs:{"id":"pacakgeFeaturesTable"}},[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("المكون غير الأساسي")]),_c('th',[_vm._v("الكمية")]),_c('th',[_vm._v("العمليات")])]),_vm._l((_vm.formData.ingredient),function(l,lIndex){return _c('tr',{key:lIndex},[_c('td',[_vm._v(" "+_vm._s(lIndex + 1)+" ")]),_c('td',[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.id${lIndex}`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('InPrimaryComponent')))]),_c('v-autocomplete',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"d-block my-2",attrs:{"name":`id${lIndex}`,"data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('InPrimaryComponent'),"hide-details":"","dense":"","outlined":"","item-value":"id","item-text":"name","items":_vm.listIngredient,"clearable":""},model:{value:(l.id),callback:function ($$v) {_vm.$set(l, "id", $$v)},expression:"l.id"}})],1),(_vm.errors.has(`addEditValidation.id${lIndex}`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.id${lIndex}`))+" ")]):_vm._e()]),_c('td',[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.quantity${lIndex}`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('Quantity')))]),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"d-block my-2",attrs:{"name":`quantity${lIndex}`,"data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('Quantity'),"hide-details":"","dense":"","outlined":""},model:{value:(l.quantity),callback:function ($$v) {_vm.$set(l, "quantity", $$v)},expression:"l.quantity"}})],1),(_vm.errors.has(`addEditValidation.quantity${lIndex}`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.quantity${lIndex}`))+" ")]):_vm._e()]),_c('td',[_c('v-icon',{staticClass:"my-0 mr-2 ml-2",attrs:{"dense":"","color":"danger"},on:{"click":function($event){return _vm.removeRow(lIndex);}}},[_vm._v("mdi-delete")])],1)])})],2)],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"float-left"},[_c('v-btn',{staticClass:"mr-2 ml-5",attrs:{"color":"success","dark":"","outlined":"","small":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("Save"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }